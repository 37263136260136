.rowColorGreen {
    background-color: #7cb305;
}

.rowColorBlu {
    background-color: #0273b2;
    color: #000c17;
    font-weight: bold;
}

